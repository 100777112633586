.MenuListItemButton {
  @apply rounded-md hover:bg-neutral-100;
}

.MenuListItemButtonActive {
  composes: MenuListItemButton;
  @apply bg-[#f0f0f0] hover:bg-[#f0f0f0] text-primary-dark;
  :global(.MuiListItemIcon-root) {
    color: inherit;
  }
}