.container {
    width: 100%
}
@media (min-width: 640px) {
    .container {
        max-width: 640px
    }
}
@media (min-width: 768px) {
    .container {
        max-width: 768px
    }
}
@media (min-width: 1024px) {
    .container {
        max-width: 1024px
    }
}
@media (min-width: 1280px) {
    .container {
        max-width: 1280px
    }
}
@media (min-width: 1536px) {
    .container {
        max-width: 1536px
    }
}
#root .visible {
    visibility: visible
}
#root .static {
    position: static
}
#root .fixed {
    position: fixed
}
#root .absolute {
    position: absolute
}
#root .relative {
    position: relative
}
#root .mt-1 {
    margin-top: 0.25rem
}
#root .block {
    display: block
}
#root .flex {
    display: flex
}
#root .table {
    display: table
}
#root .grid {
    display: grid
}
#root .hidden {
    display: none
}
#root .h-full {
    height: 100%
}
#root .h-auto {
    height: auto
}
#root .w-full {
    width: 100%
}
#root .w-auto {
    width: auto
}
#root .border-collapse {
    border-collapse: collapse
}
#root .transform {
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))
}
#root .items-center {
    align-items: center
}
#root .justify-center {
    justify-content: center
}
#root .self-start {
    align-self: flex-start
}
#root .rounded {
    border-radius: 0.25rem
}
#root .border {
    border-width: 1px
}
#root .object-cover {
    object-fit: cover
}
#root .px-6 {
    padding-left: 1.5rem;
    padding-right: 1.5rem
}
#root .uppercase {
    text-transform: uppercase
}
#root .capitalize {
    text-transform: capitalize
}
#root .outline {
    outline-style: solid
}
#root .filter {
    filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow)
}
#root .transition {
    transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, -webkit-backdrop-filter;
    transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter;
    transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter, -webkit-backdrop-filter;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 150ms
}